import React, { useEffect } from "react";
import Section from "../Layout/Section/Section";
import InnerContainer from "../Layout/InnerContainer/InnerContainer";
import RenderStackedHeadline from '../Utils/RenderStackedHeadline';

import './text-block.scss';

function renderBody(copy) {
  if (copy && copy.body) {
    return copy.body.map((line, i) => (
      <p key={i} className="body-copy animate-on-scroll">{line.line}</p>
    ))
  }
}

const TextBlock = ({ id, copy, variant }) => {
  return (
      <InnerContainer id={id} customClass={["text-block-component", `${variant ? variant : ''}`]}>
        {RenderStackedHeadline(copy)}
      </InnerContainer>
  );
};

export default TextBlock;
