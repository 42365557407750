/* eslint-disable */
import React, { useRef } from 'react';
import { Link } from 'gatsby';
import { isLoggedIn, logout, getUser } from "../../helpers/loginHelper";
import SiteNavigation from './SiteNavigation';

import './Navigation.scss';

const Navigation = ({nav}) => {
  const navMenu = useRef();

  const escListener = (e) => {
    if (e.key === "Escape") {
      handleMobileMenu();
    }
  }

  const handleLogout = () => {
    logout(); 
  }

  const handleMobileMenu = () => {
    if(window.innerWidth < 1024 || window.orientation === 90) {
      navMenu.current.classList.toggle('nav--open');
      handleNavListeners();
    }
  }

  const onScrollWindow = () => {
    navMenu.current.classList.remove('nav--open');
    window.removeEventListener('scroll', onScrollWindow, false);
  }
  
  const handleNavListeners = () => {
    if (navMenu.current.classList.contains('nav--open')) {
      window.addEventListener('keyup', escListener, false)
      window.addEventListener('scroll', onScrollWindow, false);
    } else {
      window.removeEventListener('keyup', escListener, false);
      window.removeEventListener('scroll', onScrollWindow, false);
    }
  }

  const renderUserName = () => {
    if (getUser().username === "moonPerson") {
      return "Welcome!";
    } else {
      return <>Welcome,&nbsp;<Link className="link" to={`/partners/${getUser().username}`}>{getUser().username}</Link>!</>
    }
  }
  
  const scaffoldNavigation = (navigation) => {
    let navMarkup = [];
    
    navigation.forEach((navItem, i) => {
      if (navItem.path === 'partners') {
        navMarkup.push(
          <React.Fragment key={i} >
            {isLoggedIn() ? <Link id={i} className="link" to={`/partners/${getUser().path}`}>{`${getUser().navLabel}`}</Link> : ''}
            <span id={i} className="partner-links">
              {isLoggedIn() ?
                <>
                  {renderUserName()} &nbsp;&nbsp;
                  <a href="#" onClick={handleLogout}>Sign Out</a>
                </>
              :
                <Link className="link link--partner-login" to={`/${navItem.path}`}>
                  <svg className="avatar" viewBox="0 0 11 12" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5.5" cy="3.5" r="3.5"></circle>
                    <path d="M11,11.5 C11,8.46243388 8.53756612,6 5.5,6 C2.46243388,6 0,8.46243388 0,11.5 C3.98906947,11.6202791 7.45887383,11.5471949 11,11.5 Z"></path>
                  </svg>
                  {navItem.name}
                </Link>
              }
            </span>
          </React.Fragment>
        );
      } else {
        navMarkup.push(
          <Link id={i} key={i} className="link" to={`/${navItem.path}`}>{navItem.name}</Link>
        );
      }
    });
    return navMarkup
  }

  return (
    <nav ref={navMenu} className="nav" onClick={handleMobileMenu}>
      <svg className="navigation__menu-icon" xmlns="http://www.w3.org/2000/svg">
        <path className="bar bar__top" d="M0,3 L30,3"></path>
        <path className="bar bar__cross-1" d="M0,14 L30,14"></path>
        <path className="bar bar__cross-2" d="M0,14 L30,14"></path>
        <path className="bar bar__bottom" d="M0,25 L30,25"></path>
      </svg>
      {scaffoldNavigation(SiteNavigation)}
    </nav>
  )
}

export default Navigation
