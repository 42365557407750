import React from 'react';
import RenderSocialIcon from '../../components/Utils/RenderSocialIcon';

const RenderPressQuoteText = (quote) => {
  let copyHTML = [];
  let count = 0;
  copyHTML.push(
    <>
      <h3 key={count} className="quote animate-on-scroll"><q>{quote.line}</q></h3>
      <p key={++count} className="quote-source animate-on-scroll">
        {quote.source}
        {quote.socialIcons ? RenderSocialIcon(quote.socialIcons, quote.source) : ''}
      </p>
    </>
  )

  return copyHTML;
}

export default RenderPressQuoteText;
