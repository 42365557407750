import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../Header/Header'
import SubNav from '../SubNav/SubNav'
import Footer from '../Footer/Footer'
import useSiteMetadata from './SiteMetaData'
import { withPrefix } from 'gatsby'

import '../../styles/all.scss'

async function loadFonts() {
  const museoSans = new FontFace('museo-sans', 'url(https://cmt.velocityrecap.com/cmt/fonts/museo-sans-900.woff2)', {"weight": "900", "style": "normal"});
  const scriptoRama = new FontFace('scriptorama', 'url(https://cmt.velocityrecap.com/cmt/fonts/scriptorama-tradeshow-jf.woff)', {"weight": "400", "style": "normal"});
  // wait for font to be loaded
  await museoSans.load().then(
    () => {
      // add font to document
      document.fonts.add(museoSans);
    }
  );

  await scriptoRama.load().then(
    () => {
      // add font to document
      document.fonts.add(scriptoRama);
    }
  );
  // enable font with CSS class
  document.body.classList.add('fonts-loaded');
}

const Main = ({ children }) => {
  const AnimatedElements = [
    ".grid-image__container",
    ".header-container",
    ".footer-container",
    ".header",
    "div.cover__image > picture > img",
    ".animate-on-scroll"
  ];

  // const setScrollStatus = () => {
  //   document.body.dataset.scrollStatus = (window.pageYOffset / (document.body.offsetHeight - window.innerHeight)*100);
  // }

  const handleScrollAnimations = function(entries) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0.5 && !entry.target.classList.contains("is-visible")) {
        entry.target.classList.add("is-visible");
      }
    });
  };

  function initApp() {
    console.log("%cVelocity Events","color: #E93043; font-size: 24px; font-family: Monospace; padding : 5px 234px 5px 10px;");
  }

  useEffect(() => {
    initApp();

    loadFonts();

    const observerOpt = {
      rootMargin: "30px",
      threshold: [0.25, 0.5, 0.75, 1]
    }
    const targets = Array.from(document.querySelectorAll(AnimatedElements.toString()));
    const observer = new IntersectionObserver(handleScrollAnimations, observerOpt);
    targets.forEach(function(target) {
      observer.observe(target);
    });
    // window.addEventListener('scroll', setScrollStatus, false);
  });

  const { title, description } = useSiteMetadata();
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix("/")}img/og-image.png`} />
        <body className="page" />
      </Helmet>
      <Header/>
      <main id="content" className="content">
        <SubNav/>
        {children}
      </main>
      <Footer/>
    </>
  )
}

export default Main
