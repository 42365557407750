import React, { useEffect, useRef } from "react";
import "./InnerContainer.scss";

const InnerContainer = ({id, customClass, children, ref}) => {
  const sectionElement = ref || useRef();

  useEffect(() => {
    if (customClass !== "" && typeof customClass === "string") {
      sectionElement.current.classList.add(customClass);
    } else if (customClass !== "" && typeof customClass === "object") {
      customClass.forEach((name) => {
        if (name) {
          sectionElement.current.classList.add(name);
        }
      });
    }
  });

  return (
    <section id={id} ref={sectionElement} className="inner-container">
      {children}
    </section>
  );
};

export default InnerContainer;
