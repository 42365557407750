// prefer to render the HTML with spans so the entire text is within an <H1> for scemantic purposes

import React from "react";
import RenderPressQuoteText from  './RenderPressQuoteText';

function renderBody(copy, i) {
  const body = [];

  if (copy.body) {
    copy.body.forEach(line => {
      body.push(<p key={i} className="body-copy animate-on-scroll">{line.line}</p>);
    });
  }
  return body;
}

const renderStackedHeadline = (copy) => {
  let copyHTML = [];
  let count = 0;
  
  if (copy && copy.leader) {
    copyHTML.push(<span key={count} className="leader animate-on-scroll">{copy['leader']}</span>)
    count++;
  }

  if(copy && copy.headlines && copy.headlines[0]) {
    copy.headlines.forEach ((headline, i) => {
      copyHTML.push(<h1 key={count} className={`stacked-headline stacked-headline--${++i} animate-on-scroll`}>{headline.line}</h1>)
      count++;
    })
  }

  if(copy && copy.subheads && copy.subheads[0]) {
    copy.subheads.forEach ((subhead, i) => {
      copyHTML.push(<h4 key={count} className={`subhead animate-on-scroll`}>{subhead.line}</h4>)
      count++;
    })
  }

  if (copy && copy.body) {
    copyHTML.push(renderBody(copy, "body"));
  }

  if (copy && copy.trailer) {
    copyHTML.push(<span key={count} className="trailer animate-on-scroll">{copy.trailer}</span>)
    count++;
  }

  if(copy && copy.quotes && copy.quotes[0]) {
    copy.quotes.forEach (quote => {
      copyHTML.push(RenderPressQuoteText(quote));
    })
    count++;
  }

  if (copy && copy.scriptHeadline && copy.scriptHeadline[0]) {
    copyHTML.push(<h1 key={count} className="script-headline animate-on-scroll">{copy.scriptHeadline[0].line}</h1>);
  }

  return copyHTML;

  // let copyHTML = '<h1>';

  // if (copy['leader']) {
  //   copyHTML+= `<span class="leader animate-on-scroll">${copy['leader']}</span>`
  // }

  // if(copy['headlines'] && copy['headlines'][0]) {
  //   copy['headlines'].forEach((headline, i) => {
  //     copyHTML+= `<span class="headline headline--${++i} animate-on-scroll">${headline.line}</span>`
  //   })
  // }

  // if (copy['trailer']) {
  //   copyHTML+= `<span class="trailer animate-on-scroll">${copy['trailer']}</span>`
  // }

  // if(copy['quotes'] && copy['quotes'][0]) {
  //   copy['quotes'].forEach (quote => {
  //     return RenderPressQuoteText(quote);
  //   })
  // }

  // if (copy['scriptHeadline'] && copy['scriptHeadline'][0]) {
  //   return <h1 className="script-headline animate-on-scroll">{copy['scriptHeadline'][0].line}</h1>;
  // }


  // copyHTML+= '</h1>'

  // return <div dangerouslySetInnerHTML={{__html: copyHTML}} />;
}

export default renderStackedHeadline;
