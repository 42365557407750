const SiteNavigation = [
  {
    "name": "Overview",
    "path": ""
  },
  {
    "name": "Press",
    "path": "press"
  },
  {
    "name": "Talent",
    "path": "talent"
  },
  {
    "name": "Insights",
    "path": "insights"
  },
  {
    "name": "Thank You",
    "path": "thank-you"
  },
  {
    "name": "Partners",
    "path": "partners"
  }
]

module.exports = SiteNavigation;
