import { navigate } from "gatsby";

//Checking for the window object
export const isBrowser = () => typeof window !== "undefined";

// get the user object from the localstorage
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("cmtUser")
    ? JSON.parse(window.localStorage.getItem("cmtUser"))
    : {};

// set the user object to local storage
export const setUser = (cmtUser) =>
  window.localStorage.setItem("cmtUser", JSON.stringify(cmtUser));

export const handleLogin = (authResponse) => {
  if (authResponse.isValid) {
    setUser(authResponse.userData);
  }

  return false;
};

// check the user is logged in or not
export const isLoggedIn = () => {
  const cmtUser = getUser();
  return !!cmtUser.username;
};

// logout the user
export const logout = () => {
  window.localStorage.removeItem('cmtUser');
  navigate("/");
};
