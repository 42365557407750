import React, {useEffect, useRef} from 'react';

import './subnav.scss';

const SubNav = () => {
  const subNavList = useRef();
  let sections;
  let checkScrollInterval = 0;
  
  function updateSubNav(entries) {
    entries.forEach(entry => {
      // console.log(entry);
      if (subNavList.current && entry.isIntersecting) {
        Array.from(subNavList.current.children).forEach(element => {
          element.classList.remove("is-active");
        });
        const activeNavSection = document.getElementById(`${entry.target.id}-subnav`) || null;
        if(activeNavSection) {
          document.getElementById(`${entry.target.id}-subnav`).classList.add("is-active");
        }
      }
    });
  }
  
  function showSubNav() {
    if(subNavList.current) {
      if (checkScrollInterval !== null) {
        subNavList.current.classList.add("scrolling");
        clearTimeout(checkScrollInterval);        
      }

      checkScrollInterval = setTimeout(function() {
        subNavList.current.classList.remove("scrolling");
      }, 1000);
    }

  }

  function renderSubNavLinks() {
    let subNavLinksHTML = '';
    sections = Array.from(document.getElementById("content").getElementsByTagName("section")).filter(section => section.id !== '' )
    
    sections.forEach(section => {
      subNavLinksHTML += `
      <li id="${section.id}-subnav" class="section-subnav__list-item">
        <a class="section-subnav__list-item-link" href="#${section.id}">${section.id.toLowerCase()}</a>
      </li>
      `
    });
    
    const observerOpt = {
      rootMargin: "0px",
      threshold: [0]
    }
    const observer = new IntersectionObserver(updateSubNav, observerOpt);
    sections.forEach(function(target) {
      observer.observe(target);
    });
    return subNavLinksHTML;
  }
  
  useEffect(() => {
    window.addEventListener('scroll', showSubNav, false);
    subNavList.current.innerHTML = renderSubNavLinks();
  });
  
  return(
    <ul ref={subNavList} id="subnav" className="section-subnav"></ul>
  )
}
export default SubNav;
