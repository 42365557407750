import React from 'react';

import './Footer.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <p>{`©${currentYear} Velocity, ViacomCBS`}</p>
    </footer>
  )
}

export default Footer
