import React, { useEffect, useRef } from 'react';
import './Section.scss';

const Section = ({customClass, id, children}) => {
  const sectionElement = useRef();

  useEffect(() => {
    if(customClass !== '' && typeof customClass === "string") {
      sectionElement.current.classList.add(customClass);
    } else if (
      customClass !== '' && typeof customClass === "object") {
      customClass.forEach((name) => {
        if(name) {
          sectionElement.current.classList.add(name);
        }
      })
    }
  });

  return (
    <section id={id} ref={sectionElement} className="section">
      {children}
    </section>
  )
}

export default Section;
