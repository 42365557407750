import React, { useEffect, useRef } from 'react';
import { withPrefix } from "gatsby";
import Navigation from '../Navigation/Navigation';
import PreviewCompatibleImage from '../Utils/PreviewCompatibleImage';

import './Header.scss';

const Header = ({nav}) => {  
  const headerContainer = useRef();
  let lastScrollPosition = 0;
  const PeekABooNavMenuClasses = {
    PEEK_MENU_INIT: 'header-container--inner--peek',
    PEEK_MENU_SHOW: 'header-container--inner--peek-show',
    PEEK_MENU_HIDE: 'header-container--inner--peek-hide'
  };

  const handleShowHideMenu = () => {
    if (headerContainer.current) {
      const currentScrollPosition = window.pageYOffset;
      const headerElementRectBottom = headerContainer.current.offsetHeight;
      
      const { classList } = headerContainer.current;
      const { PEEK_MENU_SHOW, PEEK_MENU_HIDE, PEEK_MENU_INIT } = PeekABooNavMenuClasses;
      const isScrollingBackToTop = (lastScrollPosition > currentScrollPosition);
      const couldBeVisible = (headerElementRectBottom < currentScrollPosition);
      
      let isBackInPlace;
      
      if (headerElementRectBottom === 0) isBackInPlace = (headerContainer.current.offsetTop >= currentScrollPosition);
      else { isBackInPlace = (headerElementRectBottom - headerElementRectBottom >= currentScrollPosition) }
  
      if (isBackInPlace && classList.contains(PEEK_MENU_INIT)) {
        headerContainer.current.classList.remove(PEEK_MENU_INIT, PEEK_MENU_SHOW, PEEK_MENU_HIDE);
      } else if (couldBeVisible) {
        if (!classList.contains(PEEK_MENU_INIT)) {
          classList.add(PEEK_MENU_INIT);
        } else if (isScrollingBackToTop && classList.contains(PEEK_MENU_INIT)) {
          classList.remove(PEEK_MENU_HIDE);
          classList.add(PEEK_MENU_SHOW);
        } else if (!isScrollingBackToTop && classList.contains(PEEK_MENU_INIT)) {
          classList.add(PEEK_MENU_HIDE);
          classList.remove(PEEK_MENU_SHOW);
        }
      }
  
      lastScrollPosition = currentScrollPosition;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleShowHideMenu, false);
  });

  return (
    <div ref={headerContainer} className="header-container">
      <div className="header-container--inner">
        <header className="header">
          <a href={withPrefix("/")} className="brand-logo__link">
            <svg className="brand-logo" viewBox="0 0 126 118">
              <path d="M7.67,103.81s1.15-10.4,1.14-10.58c.1-1.92.18-5.41.18-5.41s.27,3.52.52,5.38c0,.18,1.75,10.46,1.75,10.46l-3.59.15m5.67-22-9,.38L0,118l6.39-.27.88-8.64,4.77-.2,1.49,8.54,6.48-.27L13.34,81.84m34.21-1.45-6.35.27s-1.53,22.51-1.39,26c-.14-3.51-4.08-25.79-4.08-25.79l-6.35.27s-1.93,22.52-1.79,26c-.14-3.52-3.6-25.81-3.6-25.81l-6.52.28L23.83,117l7.4-.32S33.14,98.13,33,94.43c.33,3.69,3.62,22,3.62,22l7.58-.32,3.31-35.74m7.78,21.4s1.15-10.4,1.15-10.58c.09-1.92.17-5.4.17-5.4s.27,3.51.53,5.37c0,.18,1.74,10.46,1.74,10.46l-3.59.15m5.68-22-9,.38L47.67,116l6.39-.28.87-8.64,4.78-.2,1.49,8.54,6.48-.27L61,79.82M74.57,94.55l-.39-9.35,2.89-.12h.1A1.87,1.87,0,0,1,79,86.69,29.21,29.21,0,0,1,79.29,90a11.24,11.24,0,0,1-.22,3.22,1.56,1.56,0,0,1-1.44,1.18c-.22.05-3.06.17-3.06.17M78.5,79.09h-.4l-10.51.44,1.46,35.54,6.35-.27L74.79,100l2.28-.1,4.11,14.64,6.61-.28L82.86,97.9a4.7,4.7,0,0,0,2.26-2.77,17.68,17.68,0,0,0,.58-6,16.54,16.54,0,0,0-1.52-7,6.14,6.14,0,0,0-5.68-3m17,29.74-1-24.9,2.54-.1h.16c1.18,0,1.94.6,2.33,2a86.6,86.6,0,0,1,.83,9.38c.07,1.82.32,10.15.08,11.59a2.08,2.08,0,0,1-2.12,1.92l-2.8.12m2.3-30.55-.83,0-9,.38,1.46,35.54,9.8-.42c3.59-.15,5.25-1.6,6.3-3.65,1.26-2.42,1.26-7.87,1-15.21-.23-5.57-.7-11.75-2.14-13.7s-3.33-3-6.53-3m18.71-1.15h-.63c-5.51.23-8.7,3.71-8.49,8.88.19,4.81,1.95,7,5.22,9.68.95.77,2.81,2.34,3.27,2.72,2.68,2.25,3.62,3.77,3.74,6.75a2.43,2.43,0,0,1-2.51,2.88h-.19a2.2,2.2,0,0,1-2.43-1.85c-.06-.27-.15-1.51-.22-3.16l-6.09.25a42.32,42.32,0,0,0,.26,4.23c.45,3.76,3.25,5.93,8.28,5.93l.78,0c5.09-.22,8.4-3.36,8.41-8.56v0c0-.17,0-.33,0-.5-.19-4.77-2-7.14-5.23-10l-3.32-2.8c-2-1.74-3.61-3.42-3.71-5.87-.07-1.87.73-2.71,2.43-2.78h.19c1.53,0,2.14.85,2.36,2.21a25.15,25.15,0,0,1,.3,2.89l6.13-.26a31.85,31.85,0,0,0-.42-3.64c-.62-3.88-3-6.9-8.12-6.91" fill="#fafafa"/>
              <path d="M11.36,53.49,16,79.44l7.67-.36,4.57-25.65,0,25.43,7.36-.34V42.12l-11-2.52L19.83,64.36l-5-27.91H4V80l7.41-.34ZM59.2,77.4a10.62,10.62,0,0,0,2-6.66V36.45H53.17v34c0,2.33-1.16,3.66-3.15,3.66-2.22,0-3.32-1.28-3.32-3.72V38l-8,4.73V70.64a10.1,10.1,0,0,0,2.85,7.59ZM96.7,36.45H88.29V76l8.41-.4ZM69.46,58.92c1.16,1,3.43,3.06,4,3.55,3.27,3,4.38,4.89,4.38,8.61,0,2.22-1.17,3.44-3.32,3.44-2,0-3-1-3.22-2.44,0-.34-.11-1.89-.11-3.94H63.48a50.42,50.42,0,0,0,.11,5.27,8,8,0,0,0,1.17,3.73l19.64-.93a11.85,11.85,0,0,0,1.39-5.85c0-5.94-2.11-9-6.09-12.77-.83-.77-2.94-2.66-4-3.66-2.49-2.27-4.38-4.44-4.38-7.49,0-2.33,1.06-3.33,3.22-3.33s2.87,1.11,3.09,2.89a29.07,29.07,0,0,1,.23,3.6h7.74a35.94,35.94,0,0,0-.33-4.55c-.61-5-3.65-9-10.68-9S63.37,40.17,63.37,46.61C63.37,52.6,65.47,55.43,69.46,58.92Zm31.46,15.49c-1.11-3.55-1.55-10.21-1.55-16a61.81,61.81,0,0,1,1.27-14.26c1.11-5.27,4.65-8,11.18-8,6.81,0,9.91,3.33,10.68,9.21a57.41,57.41,0,0,1,.45,7.38h-7.86a39.82,39.82,0,0,0-.56-6.66,2.7,2.7,0,0,0-2.87-2.33,3,3,0,0,0-3.22,2.44c-.49,2.05-.61,5.94-.61,12.15A90.55,90.55,0,0,0,108.44,71a3.1,3.1,0,0,0,3.22,2.78,2.94,2.94,0,0,0,3.15-2.94,57.82,57.82,0,0,0,.33-6.11H123a51.82,51.82,0,0,1-.5,8.1,14.06,14.06,0,0,1-.38,1.58l-20.86,1C101.13,75.09,101,74.75,100.92,74.41Z" fill="#fafafa"/>
              <path d="M62,24.71,59.48,8.17A3.19,3.19,0,0,0,57.2,5.65L33.47.09a4.38,4.38,0,0,0-3,.48L9.47,13.2A3.22,3.22,0,0,0,8,16.3l2.49,16.54a3.2,3.2,0,0,0,2.28,2.52l23.73,5.56a4.38,4.38,0,0,0,3-.48l21-12.63A3.22,3.22,0,0,0,62,24.71Z" fill="#E71D36"/>
              <path d="M23,30.91c-4,.65-5.84-2.28-6.24-5l-.66-4.53a5.51,5.51,0,0,1,4.34-6.19,5,5,0,0,1,2.09.11,6.28,6.28,0,0,0,1.06.22l.1,0,.05,0a.22.22,0,0,0,.12-.22l0-.31a.21.21,0,0,1,.17-.24l1.53-.25a.21.21,0,0,1,.24.18l.67,4.62a.22.22,0,0,1-.17.25l-2.37.39a.22.22,0,0,1-.24-.19l0-.19c-.32-2.17-1.09-2.82-2.44-2.6S19.19,18,19.42,19.64l1,6.82c.13.89.62,2.94,2.34,2.66,1.48-.24,2-1.45,1.68-3.58v0l.13-.19,2.46-.4.18.14v.06A4.88,4.88,0,0,1,23,30.91Z" fill="#fafafa"/>
              <path d="M44,27.34l-5.08.83a.21.21,0,0,1-.24-.18v-.08h0l-.16-1.1h0v0a.23.23,0,0,1,.13-.23s.2-.07.48-.2.64-.29.42-1.78l-1.14-7.83-2,11.67-.18.17-.52.08-.21-.11L30.34,18.07l1.14,7.82c.22,1.49.63,1.54.91,1.57a4.93,4.93,0,0,0,.5,0,.2.2,0,0,1,.2.18l.18,1.2a.24.24,0,0,1-.18.25l-3.94.64a.2.2,0,0,1-.23-.18l-.18-1.21a.22.22,0,0,1,.13-.23l.35-.16c.25-.12.64-.3.42-1.79l-1.26-8.65c-.21-1.49-.63-1.53-.91-1.56l-.4-.05h0a.24.24,0,0,1-.17-.18l-.17-1.21a.22.22,0,0,1,.17-.25l5-.82.21.12,3.52,7.26,1.19-8a.22.22,0,0,1,.17-.19l4.71-.77a.21.21,0,0,1,.24.18l.18,1.22a.24.24,0,0,1-.12.23l-.36.16c-.26.12-.64.29-.43,1.78l1.26,8.65c.22,1.49.63,1.54.91,1.57l.39,0a.2.2,0,0,1,.18.18l.18,1.21A.22.22,0,0,1,44,27.34Z" fill="#fafafa"/>
              <path d="M53.82,15l-1.3.21a.21.21,0,0,1-.24-.18l0-.14c-.19-1.3-.72-2.63-1.63-2.48-.58.1-.76.82-.56,2.2l1.18,8.06c.21,1.48.63,1.53.91,1.56l.38,0a.22.22,0,0,1,.19.19l.17,1.2a.21.21,0,0,1-.17.25l-5,.82a.22.22,0,0,1-.24-.19l-.18-1.21a.24.24,0,0,1,.13-.23l.35-.16c.26-.12.64-.3.43-1.78l-1.18-8.06c-.2-1.38-.58-2-1.15-1.92-.9.15-1,1.55-.86,2.83l0,.19a.22.22,0,0,1-.17.25l-1.31.21a.21.21,0,0,1-.24-.18l-.66-4.54a.21.21,0,0,1,.17-.25L53.1,10a.21.21,0,0,1,.24.18L54,14.73A.23.23,0,0,1,53.82,15Z" fill="#fafafa"/>
            </svg>
          </a>
          <Navigation nav={nav}/>
        </header>
      </div>
    </div>
  )
}

export default Header
