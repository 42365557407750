import React from 'react';
import { withPrefix } from "gatsby";

const RenderSocialIcon = (icons, source) => {
  // future update: making the icon link to the user would be neat
  const iconsHTML = [];

  icons.forEach(icon => {
    iconsHTML.push (
      <img className="social-icon" src={withPrefix(`/images/icons/${icon.icon}`)} />
    )
  })

  return iconsHTML;
}

export default RenderSocialIcon;
